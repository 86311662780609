// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-covid-index-js": () => import("./../../../src/pages/covid/index.js" /* webpackChunkName: "component---src-pages-covid-index-js" */),
  "component---src-pages-do-index-js": () => import("./../../../src/pages/do/index.js" /* webpackChunkName: "component---src-pages-do-index-js" */),
  "component---src-pages-manage-index-js": () => import("./../../../src/pages/manage/index.js" /* webpackChunkName: "component---src-pages-manage-index-js" */),
  "component---src-pages-rsvp-index-js": () => import("./../../../src/pages/rsvp/index.js" /* webpackChunkName: "component---src-pages-rsvp-index-js" */),
  "component---src-pages-spotify-index-js": () => import("./../../../src/pages/spotify/index.js" /* webpackChunkName: "component---src-pages-spotify-index-js" */),
  "component---src-pages-travel-index-js": () => import("./../../../src/pages/travel/index.js" /* webpackChunkName: "component---src-pages-travel-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-restaurant-page-js": () => import("./../../../src/templates/restaurant-page.js" /* webpackChunkName: "component---src-templates-restaurant-page-js" */),
  "component---src-templates-story-page-js": () => import("./../../../src/templates/story-page.js" /* webpackChunkName: "component---src-templates-story-page-js" */)
}

